<template>
  <section>
    <b-card
      no-body
      class="mb-0"
      header-tag="header"
    >
      <div class="card-body">
        <b-card>
          <b-row>
            <b-col cols="12">
              <b-form-group
                id="input-group-1"
                label="Báo cáo"
                label-for="input-1"
              >
                <treeselect
                  id="linhVuc"
                  v-model="baoCaoId"
                  v-format-v-select
                  :options="baoCaoCbx"
                  no-options-text="Không có dữ liệu"
                  no-results-text="Không có dữ liệu"
                  placeholder="-- Chọn báo cáo --"
                  :clear-on-select="true"
                  :normalizer="normalizer"
                  :match-keys="['label', 'label2']"
                  @input="getDanhSachLinhVuc()"
                >
                  <label
                    slot="option-label"
                    slot-scope="{ node, shouldShowCount, count, labelClassName }"
                    :class="labelClassName"
                    :title="node.label"
                  >
                    - {{ node.label }}
                  </label>
                </treeselect>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-card class="border">
                <div>
                  <div class="d-flex align-items-center">
                    <span class="pl-3 text-form">LĨNH VỰC</span>
                    <span
                      class="pl-3 text-link"
                      @click="chonTatCaLinhVuc()"
                    >
                      Chọn tất cả
                    </span>
                    <span
                      class="pl-3 text-link"
                      @click="boChonTatCaLinhVuc"
                    >
                      Bỏ chọn tất cả
                    </span>
                  </div>
                  <div><hr></div>
                  <div class="body-card">
                    <b-row>
                      <b-col
                        v-for="linhVuc in linhVucCbx"
                        :key="linhVuc.id"
                        cols="4"
                        class="d-flex mt-1"
                      >
                        <b-form-checkbox
                          v-model="linhVuc.check"
                          class="ml-2"
                          @change="inputLinhVuc($event, linhVuc.id)"
                        />
                        <span>{{ linhVuc.name }}</span>

                      </b-col>
                    </b-row>
                  </div>
                </div>

              </b-card>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              class="border-card"
            >
              <b-card class="border">
                <div class="pl-3">
                  <div class="mb-1">
                    <span class="text-form">THỜI GIAN</span>
                  </div>
                  <div class="mb-2 d-flex wrap-control flex-wrap">
                    <div class="control-label">
                      Năm
                    </div>
                    <treeselect
                      v-model="time.year"
                      v-format-v-select
                      :options="YEARS()"
                      class="select-year"
                      placeholder="Chọn năm"
                      :normalizer="normalizer"
                      no-options-text="Không có dữ liệu"
                      no-results-text="Không có dữ liệu"
                      @input="xemBaoCao()"
                    />
                  </div>
                  <div class="mb-2 wrap-control">
                    <div class="control-label">
                      Thời gian
                    </div>
                    <span>
                      <b-button
                        v-for="btn in timeOption"
                        :key="btn.id"
                        :variant="btn.active ? 'outline-danger' : 'outline-white'"
                        class="mr-1"
                        @click="activeTime(btn.id)"
                      >
                        {{ btn.label }}
                      </b-button>
                    </span>
                  </div>
                  <div
                    class="mb-2 wrap-control"
                  >
                    <div class="control-label">
                      Tháng
                    </div>
                    <div class="mt-1">
                      <b-form-radio-group
                        v-model="time.month"
                        class="radio-group"
                        :options="MONTHS"
                        @input="xemBaoCao()"
                      />
                    </div>

                  </div>
                </div>

              </b-card>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <div class="d-sm-flex justify-content-end">
                <b-button
                  class="mr-1"
                  variant="danger"
                  :disabled="disableButton()"
                  @click="saveData()"
                >
                  <external-link-icon
                    size="14"
                    class="custom-class"
                    :stroke-width="3"
                  />
                  Xác nhận và gửi
                </b-button>
                <b-button
                  class="mr-1"
                  variant="warning"
                  :disabled="disableButton()"
                  @click="ketXuatBaoCao()"
                >
                  <eye-icon
                    size="14"
                    class="custom-class"
                    :stroke-width="3"
                  />
                  Kết xuất
                </b-button>
                <b-button
                  variant="primary"
                  class="btn-primary"
                  :disabled="disableButton()"
                  @click="onPrint()"
                >
                  <printer-icon
                    size="14"
                    class="custom-class"
                    :stroke-width="3"
                  />
                  In
                </b-button>
              </div>
            </b-col>
          </b-row>
          <div>
            <div class="text">
              <div class="text-center">
                <h3>BÁO CÁO TỔNG HỢP TÌNH HÌNH GIẢI QUYẾT THỦ TỤC HÀNH CHÍNH CỦA ĐƠN VỊ</h3>
              </div>
              <b-row>
                <b-col cols="12">
                  <div class="text-center mt-1">
                    <h3>
                      Kỳ báo cáo : {{ getTimeSelected() }}
                    </h3>

                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <div class="text-center">
                    <h3>
                      (Từ ngày {{ formatDate(filter.tuNgay) }} đến ngày {{ formatDate(filter.denNgay) }})
                    </h3>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <div class="d-flex justify-content-end">
                    <h3>
                      Đơn vị tính : Số hồ sơ
                    </h3>

                  </div>
                </b-col>
              </b-row>
            </div>
            <div>
              <app-table
                class="mt-1"
                :columns="columns"
                :table-data="tableData"
                :pagination="false"
              />
            </div>
            <div
              v-show="false"
              ref="docBody"
            >
              <app-table
                class="mt-1"
                :columns="columnsPrint"
                :table-data="tableData"
                :pagination="false"
              />
            </div>
          </div>
        </b-card>
      </div>
      <iframe
        id="printing-frame"
        name="print_frame"
        style="display:none;"
      />
    </b-card>
  </section>
</template>

<script>

import {
  BCard,
  BFormGroup,
  BFormCheckbox,
  BRow,
  BCol,
  BButton,
  BFormRadioGroup,
  BFormInput,
  VBTooltip,
} from 'bootstrap-vue'
import { PER_PAGE_OPTIONS } from '@/constants/constants'
import {
  ExternalLinkIcon,
  PrinterIcon,
  EyeIcon,
} from 'vue-feather-icons'
import addCssSemantic from '@/mixins/mixins'
import END_POINTS from '@/api/endpoints'
import AppTable from '@/components/AppTable.vue'
import {
  removeDiacritical, formatDateTime, formatDateRequest, formatDate, formatNumberToCost,
} from '@/utils/index'
import _filter from 'lodash/filter'
import _isEmpty from 'lodash/isEmpty'
import { YEARS, QUARTERS, MONTHS } from '../../constants/constants'

export const TABLE_DEFAULT = [
  {
    STT: '(1)',
    tenLinhVuc: '(2)',
    linhVucId: '',
    nhanGiaiQuyet_TongSoHoSo: '(3)',
    nhanGiaiQuyet_TonTruoc: '(4)',
    nhanGiaiQuyet_TrongKy_Tong: '(5)',
    nhanGiaiQuyet_TrongKy_MotCua: '(6)',
    nhanGiaiQuyet_TrongKy_TrucTuyen: '(7)',
    kqgQ_TongSo: '(8)',
    kqgQ_TinhHinhThucHien_TruocHan: '(9)',
    kqgQ_TinhHinhThucHien_DungHan: '(10)',
    kqgQ_TinhHinhThucHien_QuaHan: '(11)',
    kqgQ_TuChoiGiaiQuyet: '(12)',
    kqgQ_TraKetQua_DaTra: '(13)',
    kqgQ_TraKetQua_ChuaTra: '(14)',
    dangGiaiQuyet_TongSo: '(15)',
    dangGiaiQuyet_TrongHan: '(16)',
    dangGiaiQuyet_QuaHan: '(17)',
    tamDungBoSungDieuKien: '(18)',
    rutKhongGiaiQuyet: '(19)',
    tyLeSomVaDungHan: '(20)',
  },
  {
    STT: 0,
    tenLinhVuc: 0,
    linhVucId: '',
    nhanGiaiQuyet_TongSoHoSo: 0,
    nhanGiaiQuyet_TonTruoc: 0,
    nhanGiaiQuyet_TrongKy_Tong: 0,
    nhanGiaiQuyet_TrongKy_MotCua: 0,
    nhanGiaiQuyet_TrongKy_TrucTuyen: 0,
    kqgQ_TongSo: 0,
    kqgQ_TinhHinhThucHien_TruocHan: 0,
    kqgQ_TinhHinhThucHien_DungHan: 0,
    kqgQ_TinhHinhThucHien_QuaHan: 0,
    kqgQ_TuChoiGiaiQuyet: 0,
    kqgQ_TraKetQua_DaTra: 0,
    kqgQ_TraKetQua_ChuaTra: 0,
    dangGiaiQuyet_TongSo: 0,
    dangGiaiQuyet_TrongHan: 0,
    dangGiaiQuyet_QuaHan: 0,
    tamDungBoSungDieuKien: 0,
    rutKhongGiaiQuyet: 0,
    tyLeSomVaDungHan: 0,
  },
]

export default {
  name: 'BaoCaoThongKe',
  components: {
    BFormGroup,
    BCard,
    AppTable,
    ExternalLinkIcon,
    PrinterIcon,
    EyeIcon,
    BFormRadioGroup,
    BButton,
    BFormCheckbox,
    BRow,
    BCol,
    // eslint-disable-next-line vue/no-unused-components
    BFormInput,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [addCssSemantic],
  data() {
    return {
      YEARS,
      QUARTERS,
      MONTHS,
      perPageOptions: PER_PAGE_OPTIONS,
      optionsLinhVuc: [],
      option: [],
      baoCaoCbx: [],
      baoCaoId: null,
      linhVucCbx: [],
      donViCbx: [],
      baoCao_Cbx: [],
      rows: [],
      totalPages: 0,
      selected: '',
      totalRows: 0,
      isLoading: false,
      selectedItems: [],
      danhSachThuTucHanhChinh: [],
      danhSachLinhVuc: [],
      tongSoHoSo: 0,
      total: 0,
      title: 'Danh sách hồ sơ',
      pageChange: [],
      danhSachTrangThaiHoSo: [],
      danhSachHoSo: [],
      columnsDs: [
        {
          label: 'STT',
          thClass: 'text-center custom-style',
          tdClass: 'text-center',
          width: '50px',
          field: 'STT',
        },
        {
          label: 'Số biên nhận',
          field: 'code',
          width: '200px',
          thClass: 'text-center',
        },
        {
          label: 'Lĩnh vực',
          field: 'tenLinhVuc',
          width: '200px',
          thClass: 'text-center',
        },
        {
          label: 'Tên thủ tục hành chính',
          field: 'tenThuTucHanhChinh',
          width: '250px',
          thClass: 'text-center',
        },
        {
          label: 'Chủ hồ sơ',
          field: 'chuHoSo_HoVaTen',
          width: '150px',
          thClass: 'text-center',
        },
        {
          label: 'Số điện thoại',
          field: 'chuHoSo_SoDienThoai',
          width: '150px',
          thClass: 'text-center',
        },
        {
          label: 'Địa chỉ',
          field: 'chuHoSo_DiaChiDayDu',
          width: '250px',
          thClass: 'text-center',
        },
        {
          label: 'Ngày nộp',
          field: 'ngayNop',
          width: '250px',
          thClass: 'text-center',
        },
        {
          label: 'Ngày hẹn trả',
          field: 'ngayHenTraKq',
          width: '150px',
          thClass: 'text-center',
        },
        {
          label: 'Thao tác',
          tdClass: 'vertical-align-middle',
          thClass: 'text-center',
          field: 'actions',
          width: '100px',
        },
      ],
      columns: [
        {
          field: 'STT',
          key: 'a',
          title: 'STT',
          renderBodyCell: ({ rowIndex }) => {
            if (rowIndex === 0) {
              return '(1)'
            }
            if (rowIndex === this.tableData.length - 1) {
              return ''
            }
            return rowIndex
          },
        },
        {
          field: 'tenLinhVuc',
          key: 'b',
          title: 'Lĩnh vực',
          width: '200px',
          renderBodyCell: ({ row, column, rowIndex }) => {
            const text = row[column.field]
            if (rowIndex === 0) {
              return <span><p class="text-center">{text}</p></span>
            }
            if (rowIndex === this.tableData.length - 1) {
              return <span><p class="text-check text-weight">Tổng số</p></span>
            }
            return this.baoCaoId ? <span><p class="text-check">{text}</p></span> : ''
          },
        },
        {
          field: 'nhanGiaiQuyet',
          key: 'f',
          title: 'Nhận giải quyết',
          children: [
            {
              field: 'nhanGiaiQuyet_TongSoHoSo',
              key: 'g',
              title: 'Tổng số',
              renderBodyCell: ({ row, column, rowIndex }) => {
                const text = row[column.field]
                if (rowIndex === 0) {
                  return <span>{text}</span>
                }
                if (rowIndex === this.tableData.length - 1) {
                  return this.baoCaoId ? <span><p class="text-right-check">{text}</p></span> : <span><p class="text-right-check">0</p></span>
                }
                return this.baoCaoId ? <input
                    v-model={row[column.field]}
                    type="number"
                    class="form-control custom-control"
                    style="width:100%; text-align: right;"
                    value={text}
                    onInput={e => this.setTotal(column.field, rowIndex, e)}
                /> : null
              },
            },
            {
              field: 'nhanGiaiQuyet_TonTruoc',
              key: 'h',
              title: 'Tồn trước',
              renderBodyCell: ({ row, column, rowIndex }) => {
                const text = row[column.field]
                if (rowIndex === 0) {
                  return <span>{text}</span>
                }
                if (rowIndex === this.tableData.length - 1) {
                  return this.baoCaoId ? <span><p class="text-right-check">{text}</p></span> : <span><p class="text-right-check">0</p></span>
                }
                return this.baoCaoId ? <input
                    v-model={row[column.field]}
                    type="number"
                    class="form-control custom-control"
                    style="width:100%; text-align: right;"
                    value={text}
                    onInput={e => this.setTotal(column.field, rowIndex, e)}
                /> : null
              },
            },
            {
              field: 'nhanGiaiQuyet_TrongKy',
              key: 'i',
              title: 'Trong kỳ',
              children: [
                {
                  field: 'nhanGiaiQuyet_TrongKy_Tong',
                  key: 'i1',
                  title: 'Tổng',
                  renderBodyCell: ({ row, column, rowIndex }) => {
                    const text = row[column.field]
                    if (rowIndex === 0) {
                      return <span>{text}</span>
                    }
                    if (rowIndex === this.tableData.length - 1) {
                      return this.baoCaoId ? <span><p class="text-right-check">{text}</p></span> : <span><p class="text-right-check">0</p></span>
                    }
                    return this.baoCaoId ? <input
                        v-model={row[column.field]}
                        type="number"
                        class="form-control custom-control"
                        style="width:100%; text-align: right;"
                        value={text}
                        onInput={e => this.setTotal(column.field, rowIndex, e)}
                    /> : null
                  },
                },
                {
                  field: 'nhanGiaiQuyet_TrongKy_MotCua',
                  key: 'i2',
                  title: 'Một cửa',
                  renderBodyCell: ({ row, column, rowIndex }) => {
                    const text = row[column.field]
                    if (rowIndex === 0) {
                      return <span>{text}</span>
                    }
                    if (rowIndex === this.tableData.length - 1) {
                      return this.baoCaoId ? <span><p class="text-right-check">{text}</p></span> : <span><p class="text-right-check">0</p></span>
                    }
                    return this.baoCaoId ? <input
                        v-model={row[column.field]}
                        type="number"
                        class="form-control custom-control"
                        style="width:100%; text-align: right;"
                        value={text}
                        onInput={e => this.setTotal(column.field, rowIndex, e)}
                    /> : null
                  },
                },
                {
                  field: 'nhanGiaiQuyet_TrongKy_TrucTuyen',
                  key: 'i3',
                  title: 'Trực tuyến',
                  renderBodyCell: ({ row, column, rowIndex }) => {
                    const text = row[column.field]
                    if (rowIndex === 0) {
                      return <span>{text}</span>
                    }
                    if (rowIndex === this.tableData.length - 1) {
                      return <span><p class="text-right-check">{text}</p></span>
                    }
                    return this.baoCaoId ? <input
                        v-model={row[column.field]}
                        type="number"
                        class="form-control custom-control"
                        style="width:100%; text-align: right;"
                        value={text}
                        onInput={e => this.setTotal(column.field, rowIndex, e)}
                    /> : null
                  },
                },
              ],
            },

          ],
        },
        {
          field: 'ketQuaGiaiQuyet',
          key: 'j',
          title: 'Kết quả giải quyết',
          children: [
            {
              field: 'kqgQ_TongSo',
              key: 'n',
              title: 'Tổng số',
              renderBodyCell: ({ row, column, rowIndex }) => {
                const text = row[column.field]
                if (rowIndex === 0) {
                  return <span>{text}</span>
                }
                if (rowIndex === this.tableData.length - 1) {
                  return this.baoCaoId ? <span><p class="text-right-check">{text}</p></span> : <span><p class="text-right-check">0</p></span>
                }
                return this.baoCaoId ? <input
                    v-model={row[column.field]}
                    type="number"
                    class="form-control custom-control"
                    style="width:100%; text-align: right;"
                    value={text}
                    onInput={e => this.setTotal(column.field, rowIndex, e)}
                /> : null
              },
            },
            {
              field: 'ketQuaGiaiQuyet_TinhHinhThucHien',
              key: 'm',
              title: 'Tình hình thực hiện',
              children: [
                {
                  field: 'kqgQ_TinhHinhThucHien_TruocHan',
                  key: 'm1',
                  title: 'Trước hạn',
                  renderBodyCell: ({ row, column, rowIndex }) => {
                    const text = row[column.field]
                    if (rowIndex === 0) {
                      return <span>{text}</span>
                    }
                    if (rowIndex === this.tableData.length - 1) {
                      return this.baoCaoId ? <span><p class="text-right-check">{text}</p></span> : <span><p class="text-right-check">0</p></span>
                    }
                    return this.baoCaoId ? <input
                        v-model={row[column.field]}
                        type="number"
                        class="form-control custom-control"
                        style="width:100%; text-align: right;"
                        value={text}
                        onInput={e => this.setTotal(column.field, rowIndex, e)}
                    /> : null
                  },
                },
                {
                  field: 'kqgQ_TinhHinhThucHien_DungHan',
                  key: 'm2',
                  title: 'Đúng hạn',
                  renderBodyCell: ({ row, column, rowIndex }) => {
                    const text = row[column.field]
                    if (rowIndex === 0) {
                      return <span>{text}</span>
                    }
                    if (rowIndex === this.tableData.length - 1) {
                      return this.baoCaoId ? <span><p class="text-right-check">{text}</p></span> : <span><p class="text-right-check">0</p></span>
                    }
                    return this.baoCaoId ? <input
                        v-model={row[column.field]}
                        type="number"
                        class="form-control custom-control"
                        style="width:100%; text-align: right;"
                        value={text}
                        onInput={e => this.setTotal(column.field, rowIndex, e)}
                    /> : null
                  },
                },
                {
                  field: 'kqgQ_TinhHinhThucHien_QuaHan',
                  key: 'm3',
                  title: 'Quá hạn',
                  renderBodyCell: ({ row, column, rowIndex }) => {
                    const text = row[column.field]
                    if (rowIndex === 0) {
                      return <span>{text}</span>
                    }
                    if (rowIndex === this.tableData.length - 1) {
                      return this.baoCaoId ? <span><p class="text-right-check">{text}</p></span> : <span><p class="text-right-check">0</p></span>
                    }
                    return this.baoCaoId ? <input
                        v-model={row[column.field]}
                        type="number"
                        class="form-control custom-control"
                        style="width:100%; text-align: right;"
                        value={text}
                        onInput={e => this.setTotal(column.field, rowIndex, e)}
                    /> : null
                  },
                },
              ],
            },
            {
              field: 'kqgQ_TuChoiGiaiQuyet',
              key: 'tr',
              title: 'Từ chối giải quyết',
              renderBodyCell: ({ row, column, rowIndex }) => {
                const text = row[column.field]
                if (rowIndex === 0) {
                  return <span>{text}</span>
                }
                if (rowIndex === this.tableData.length - 1) {
                  return this.baoCaoId ? <span><p class="text-right-check">{text}</p></span> : <span><p class="text-right-check">0</p></span>
                }
                return this.baoCaoId ? <input
                    v-model={row[column.field]}
                    type="number"
                    class="form-control custom-control"
                    style="width:100%; text-align: right;"
                    value={text}
                    onInput={e => this.setTotal(column.field, rowIndex, e)}
                /> : null
              },
            },
            {
              field: 'ketQuaGiaiQuyet_TraKetQua',
              key: 'q',
              title: 'Trả kết quả',
              children: [
                {
                  field: 'kqgQ_TraKetQua_DaTra',
                  key: 'q1',
                  title: 'Đã trả',
                  renderBodyCell: ({ row, column, rowIndex }) => {
                    const text = row[column.field]
                    if (rowIndex === 0) {
                      return <span>{text}</span>
                    }
                    if (rowIndex === this.tableData.length - 1) {
                      return this.baoCaoId ? <span><p class="text-right-check">{text}</p></span> : <span><p class="text-right-check">0</p></span>
                    }
                    return this.baoCaoId ? <input
                        v-model={row[column.field]}
                        type="number"
                        class="form-control custom-control"
                        style="width:100%; text-align: right;"
                        value={text}
                        onInput={e => this.setTotal(column.field, rowIndex, e)}
                    /> : null
                  },
                },
                {
                  field: 'kqgQ_TraKetQua_ChuaTra',
                  key: 'q2',
                  title: 'Chưa trả',
                  renderBodyCell: ({ row, column, rowIndex }) => {
                    const text = row[column.field]
                    if (rowIndex === 0) {
                      return <span>{text}</span>
                    }
                    if (rowIndex === this.tableData.length - 1) {
                      return this.baoCaoId ? <span><p class="text-right-check">{text}</p></span> : <span><p class="text-right-check">0</p></span>
                    }
                    return this.baoCaoId ? <input
                        v-model={row[column.field]}
                        type="number"
                        class="form-control custom-control"
                        style="width:100%; text-align: right;"
                        value={text}
                        onInput={e => this.setTotal(column.field, rowIndex, e)}
                    /> : null
                  },
                },
              ],
            },
          ],
        },
        {
          field: 'dangGiaiQuyet',
          title: 'Đang giải quyết',
          children: [
            {
              field: 'dangGiaiQuyet_TongSo',
              title: 'Tổng số',
              key: 'x',
              renderBodyCell: ({ row, column, rowIndex }) => {
                const text = row[column.field]
                if (rowIndex === 0) {
                  return <span>{text}</span>
                }
                if (rowIndex === this.tableData.length - 1) {
                  return this.baoCaoId ? <span><p class="text-right-check">{text}</p></span> : <span><p class="text-right-check">0</p></span>
                }
                return this.baoCaoId ? <input
                    v-model={row[column.field]}
                    type="number"
                    class="form-control custom-control"
                    style="width:100%; text-align: right;"
                    value={text}
                    onInput={e => this.setTotal(column.field, rowIndex, e)}
                /> : null
              },
            },
            {
              field: 'dangGiaiQuyet_TrongHan',
              title: 'Trong hạn',
              key: 'y',
              renderBodyCell: ({ row, column, rowIndex }) => {
                const text = row[column.field]
                if (rowIndex === 0) {
                  return <span>{text}</span>
                }
                if (rowIndex === this.tableData.length - 1) {
                  return this.baoCaoId ? <span><p class="text-right-check">{text}</p></span> : <span><p class="text-right-check">0</p></span>
                }
                return this.baoCaoId ? <input
                    v-model={row[column.field]}
                    type="number"
                    class="form-control custom-control"
                    style="width:100%; text-align: right;"
                    value={text}
                    onInput={e => this.setTotal(column.field, rowIndex, e)}
                /> : null
              },
            },
            {
              field: 'dangGiaiQuyet_QuaHan',
              title: 'Quá hạn',
              key: 'l',
              renderBodyCell: ({ row, column, rowIndex }) => {
                const text = row[column.field]
                if (rowIndex === 0) {
                  return <span>{text}</span>
                }
                if (rowIndex === this.tableData.length - 1) {
                  return this.baoCaoId ? <span><p class="text-right-check">{text}</p></span> : <span><p class="text-right-check">0</p></span>
                }
                return this.baoCaoId ? <input
                    v-model={row[column.field]}
                    type="number"
                    class="form-control custom-control"
                    style="width:100%; text-align: right;"
                    value={text}
                    onInput={e => this.setTotal(column.field, rowIndex, e)}
                /> : null
              },
            },
          ],
        },
        {
          field: 'tamDungBoSungDieuKien',
          key: 'b2',
          title: 'Tạm dừng bổ sung điều kiện',
          renderBodyCell: ({ row, column, rowIndex }) => {
            const text = row[column.field]
            if (rowIndex === 0) {
              return <span>{text}</span>
            }
            if (rowIndex === this.tableData.length - 1) {
              return this.baoCaoId ? <span><p class="text-right-check">{text}</p></span> : <span><p class="text-right-check">0</p></span>
            }
            return this.baoCaoId ? <input
                v-model={row[column.field]}
                type="number"
                class="form-control custom-control"
                style="width:100%; text-align: right;"
                value={text}
                onInput={e => this.setTotal(column.field, rowIndex, e)}
            /> : null
          },
        },
        {
          field: 'rutKhongGiaiQuyet',
          key: 'b3',
          title: 'Rút không giải quyết',
          renderBodyCell: ({ row, column, rowIndex }) => {
            const text = row[column.field]
            if (rowIndex === 0) {
              return <span>{text}</span>
            }
            if (rowIndex === this.tableData.length - 1) {
              return this.baoCaoId ? <span><p class="text-right-check">{text}</p></span> : <span><p class="text-right-check">0</p></span>
            }
            return this.baoCaoId ? <input
                v-model={row[column.field]}
                type="number"
                class="form-control custom-control"
                style="width:100%; text-align: right;"
                value={text}
                onInput={e => this.setTotal(column.field, rowIndex, e)}
            /> : null
          },
        },
        {
          field: 'tyLeSomVaDungHan',
          key: 'b4',
          title: 'Tỉ lệ sớm và đúng hạn',
          renderBodyCell: ({ row, column, rowIndex }) => {
            const text = row[column.field]
            if (rowIndex === 0) {
              return <span>{text}</span>
            }
            if (rowIndex === this.tableData.length - 1) {
              return this.baoCaoId ? <span><p class="text-right-check">{text}</p></span> : <span><p class="text-right-check">0</p></span>
            }
            return this.baoCaoId ? <input
                v-model={row[column.field]}
                type="number"
                class="form-control custom-control"
                style="width:100%; text-align: right;"
                value={text}
                onInput={e => this.setTotal(column.field, rowIndex, e)}
            /> : ''
          },
        },
      ],
      columnsPrint: [
        {
          field: 'STT',
          key: 'a',
          title: 'STT',
          renderBodyCell: ({ rowIndex }) => {
            if (rowIndex === 0) {
              return '(1)'
            }
            if (rowIndex === this.tableData.length - 1) {
              return ''
            }
            return rowIndex
          },
        },
        {
          field: 'tenLinhVuc',
          key: 'b',
          title: 'Lĩnh vực',
          width: '200px',
          renderBodyCell: ({ row, column, rowIndex }) => {
            const text = row[column.field]
            if (rowIndex === this.tableData.length - 1) {
              return 'Tổng số'
            }
            return <span>{text}</span>
          },
        },
        {
          field: 'nhanGiaiQuyet',
          key: 'f',
          title: 'Nhận giải quyết',
          children: [
            {
              field: 'nhanGiaiQuyet_TongSoHoSo',
              key: 'g',
              title: 'Tổng số',
              renderBodyCell: ({ row, column }) => <span>{row[column.field]}</span>,
            },
            {
              field: 'nhanGiaiQuyet_TonTruoc',
              key: 'h',
              title: 'Tồn trước',
              renderBodyCell: ({ row, column }) => <span>{row[column.field]}</span>,
            },
            {
              field: 'nhanGiaiQuyet_TrongKy',
              key: 'i',
              title: 'Trong kỳ',
              children: [
                {
                  field: 'nhanGiaiQuyet_TrongKy_Tong',
                  key: 'i1',
                  title: 'Tổng',
                  renderBodyCell: ({ row, column }) => <span>{row[column.field]}</span>,
                },
                {
                  field: 'nhanGiaiQuyet_TrongKy_MotCua',
                  key: 'i2',
                  title: 'Một cửa',
                  renderBodyCell: ({ row, column }) => <span>{row[column.field]}</span>,
                },
                {
                  field: 'nhanGiaiQuyet_TrongKy_TrucTuyen',
                  key: 'i3',
                  title: 'Trực tuyến',
                  renderBodyCell: ({ row, column }) => <span>{row[column.field]}</span>,
                },
              ],
            },

          ],
        },
        {
          field: 'ketQuaGiaiQuyet',
          key: 'j',
          title: 'Kết quả giải quyết',
          children: [
            {
              field: 'kqgQ_TongSo',
              key: 'n',
              title: 'Tổng số',
              renderBodyCell: ({ row, column }) => <span>{row[column.field]}</span>,
            },
            {
              field: 'ketQuaGiaiQuyet_TinhHinhThucHien',
              key: 'm',
              title: 'Tình hình thực hiện',
              children: [
                {
                  field: 'kqgQ_TinhHinhThucHien_TruocHan',
                  key: 'm1',
                  title: 'Trước hạn',
                  renderBodyCell: ({ row, column }) => <span>{row[column.field]}</span>,
                },
                {
                  field: 'kqgQ_TinhHinhThucHien_DungHan',
                  key: 'm2',
                  title: 'Đúng hạn',
                  renderBodyCell: ({ row, column }) => <span>{row[column.field]}</span>,
                },
                {
                  field: 'kqgQ_TinhHinhThucHien_QuaHan',
                  key: 'm3',
                  title: 'Quá hạn',
                  renderBodyCell: ({ row, column }) => <span>{row[column.field]}</span>,
                },
              ],
            },
            {
              field: 'kqgQ_TuChoiGiaiQuyet',
              key: 'tr',
              title: 'Từ chối giải quyết',
              renderBodyCell: ({ row, column }) => <span>{row[column.field]}</span>,
            },
            {
              field: 'ketQuaGiaiQuyet_TraKetQua',
              key: 'q',
              title: 'Trả kết quả',
              children: [
                {
                  field: 'kqgQ_TraKetQua_DaTra',
                  key: 'q1',
                  title: 'Đã trả',
                  renderBodyCell: ({ row, column }) => <span>{row[column.field]}</span>,
                },
                {
                  field: 'kqgQ_TraKetQua_ChuaTra',
                  key: 'q2',
                  title: 'Chưa trả',
                  renderBodyCell: ({ row, column }) => <span>{row[column.field]}</span>,
                },
              ],
            },
          ],
        },
        {
          field: 'dangGiaiQuyet',
          title: 'Đang giải quyết',
          width: 260,
          children: [
            {
              field: 'dangGiaiQuyet_TongSo',
              title: 'Tổng số',
              key: 'x',
              renderBodyCell: ({ row, column }) => <span>{row[column.field]}</span>,
            },
            {
              field: 'dangGiaiQuyet_TrongHan',
              title: 'Trong hạn',
              key: 'y',
              renderBodyCell: ({ row, column }) => <span>{row[column.field]}</span>,
            },
            {
              field: 'dangGiaiQuyet_QuaHan',
              title: 'Quá hạn',
              key: 'l',
              renderBodyCell: ({ row, column }) => <span>{row[column.field]}</span>,
            },
          ],
        },
        {
          field: 'tamDungBoSungDieuKien',
          key: 'b2',
          title: 'Tạm dừng bổ sung điều kiện',
          renderBodyCell: ({ row, column }) => <span>{row[column.field]}</span>,
        },
        {
          field: 'rutKhongGiaiQuyet',
          key: 'b3',
          title: 'Rút không giải quyết',
          renderBodyCell: ({ row, column }) => <span>{row[column.field]}</span>,
        },
        {
          field: 'tyLeSomVaDungHan',
          key: 'b4',
          title: 'Tỉ lệ sớm và đúng hạn',
          renderBodyCell: ({ row, column }) => <span>{row[column.field]}</span>,
        },
      ],
      tableData: [
        ...TABLE_DEFAULT,
      ],
      filter: {
        dataDonViId: [],
        dataLinhVucId: [],
        tuNgay: '',
        denNgay: '',
        tanSuatBaoCao: '',
      },
      time: {
        year: new Date().getFullYear(),
        quarter: null,
        month: 0,
        date: null,
      },
      activeTimeSelect: 1,
      timeOption: [
        {
          label: 'Tháng',
          id: 1,
          active: true,
        },
      ],
      listBackup: [],
    }
  },
  // watch: {
  //   'time.month': {
  //     handler() {
  //       if (this.baoCaoId) {
  //         this.getDanhSachLinhVuc()
  //       }
  //     },
  //     deep: true,
  //   },
  // },
  created() {
    this.getDanhMucBaoCao()
  },
  methods: {
    formatDateRequest,
    formatNumberToCost,
    formatDateTime,
    formatDate,
    saveData() {
      const userInfo = JSON.parse(localStorage.getItem('userInfo'))
      const payload = this.tableData.filter(row => row.linhVucId).map((row, index) => ({
        ...row,
        baoCaoId: this.baoCaoId,
        dataFilterLinhVucId: this.linhVucCbx.filter(linhVuc => linhVuc.check).map(linhVuc => linhVuc.id),
        donViId: userInfo ? userInfo.donViTrucThuoc.id : '',
        tuNgay: formatDateRequest(this.filter.tuNgay),
        denNgay: formatDateRequest(this.filter.denNgay),
        tanSuatBaoCao: 'THANG',
        stt: index + 1,
      }))
      this.$axios.post(END_POINTS.BAO_CAO_THONG_KE.NHAP_LIEU_THONG_KE, payload, false).then(res => {
        if (res.data?.succeeded) {
          this.$toast.success(res.data.message)
        } else {
          this.$toast.error(res.data.message)
        }
      })
    },
    xemBaoCao() {
      if (this.time.year) {
        const date = this.layThoiGianBaoCao()
        this.filter.tuNgay = date.dateBefore
        this.filter.denNgay = date.dateAfter
      } if (this.baoCaoId) {
        this.chiTietBaoCao()
      }
    },
    ketXuatBaoCao() {
      const userInfo = JSON.parse(localStorage.getItem('userInfo'))
      if (this.time.year) {
        const date = this.layThoiGianBaoCao()
        this.filter = {
          tuNgay: formatDateRequest(date.dateBefore),
          dataFilterLinhVucId: this.linhVucCbx.filter(linhVuc => linhVuc.check).map(linhVuc => linhVuc.id),
          denNgay: formatDateRequest(date.dateAfter),
          tanSuatBaoCao: 'THANG',
          baoCaoId: this.baoCaoId,
          donViId: userInfo ? userInfo.donViTrucThuoc.id : '',
        }
        this.$axios.post(END_POINTS.BAO_CAO_THONG_KE.EXPORT_NHAP_LIEU_BAO_CAO, this.filter, false).then(res => {
          if (res.data?.succeeded) {
            this.ketXuat(res)
            this.$toast.success(res.data.message)
          } else {
            this.$toast.error(res.data.message)
          }
        })
      }
    },
    ketXuat(res) {
      const docUrl = document.createElement('a')
      docUrl.href = `data:application/octet-stream;base64,${res.data.fileData}`
      docUrl.setAttribute('download', res.data.fileName)
      docUrl.setAttribute('target', '_blank')
      docUrl.click()
    },
    disableButton() {
      if (this.tableData.length === 2) {
        return true
      }
      if (!this.time.year) {
        return true
      }
      // if (!this.filter.dataDonViId) {
      //   return true
      // }
      // if (!this.filter.dataLinhVucId) {
      //   return true
      // }
      if (!this.baoCaoId) {
        return true
      }
      switch (this.activeTimeSelect) {
        case 1:
          if (!this.time.month && this.time.month !== 0) {
            return true
          }
          return false
        default: return true
      }
    },
    layThoiGianBaoCao() {
      let dateBefore = new Date()
      let dateAfter = new Date()
      switch (this.activeTimeSelect) {
        case 1:
          if (this.time.month === 2) {
            dateBefore.setFullYear(this.time.year)
            dateBefore.setMonth(1)
            dateBefore.setDate(15)
            dateAfter.setFullYear(this.time.year)
            dateAfter.setMonth(2)
            dateAfter.setDate(14)
          } else {
            dateBefore.setFullYear(this.time.year)
            dateBefore.setMonth(this.time.month - 1)
            dateBefore.setDate(15)
            dateAfter.setFullYear(this.time.year)
            dateAfter.setMonth(this.time.month)
            dateAfter.setDate(14)
          }
          if (this.time.month === 3) {
            dateBefore.setFullYear(this.time.year)
            dateBefore.setMonth(2)
            dateBefore.setDate(15)
            dateAfter.setFullYear(this.time.year)
            dateAfter.setMonth(3)
            dateAfter.setDate(14)
          } else {
            dateBefore.setFullYear(this.time.year)
            dateBefore.setMonth(this.time.month - 1)
            dateBefore.setDate(15)
            dateAfter.setFullYear(this.time.year)
            dateAfter.setMonth(this.time.month)
            dateAfter.setDate(14)
          }

          return {
            dateBefore,
            dateAfter,
          }
        case 2:
          dateBefore.setFullYear(this.time.year)
          dateBefore.setMonth(this.time.quarter - 1)
          dateBefore.setDate(15)
          dateAfter.setFullYear(this.time.year)
          dateAfter.setMonth(this.time.quarter + 2)
          dateAfter.setDate(14)
          return {
            dateBefore,
            dateAfter,
          }
        case 3:
          dateBefore.setFullYear(this.time.year - 1)
          dateBefore.setMonth(11)
          dateBefore.setDate(15)
          dateAfter.setFullYear(this.time.year)
          dateAfter.setMonth(11)
          dateAfter.setDate(14)
          return {
            dateBefore,
            dateAfter,
          }
        case 4:
          if (this.time.date) {
            [dateBefore, dateAfter] = this.time.date
          }
          return {
            dateBefore,
            dateAfter,
          }
        default: return {
          dateBefore,
          dateAfter,
        }
      }
    },
    detailHoSo(data) {
      this.$router.push({ path: '/ho-so/chi-tiet', query: { id: data.id } })
    },
    getDanhMucBaoCao() {
      this.$axios.get(`${END_POINTS.BAO_CAO.COMBOBOX}?phanQuyen=${true}`).then(response => {
        if (response.data && response.data.succeeded) {
          this.baoCaoCbx = response.data.data
        }
      })
    },
    async getDanhSachLinhVuc() {
      if (this.baoCaoId) {
        await this.$axios.get(`${END_POINTS.LINH_VUC.COMBOBOX}?baoCaoId=${this.baoCaoId}`).then(res => {
          if (res.data && res.data.succeeded) {
            this.linhVucCbx = res.data.data.map(item => ({
              ...item, check: false,
            }))
          }
        })
        this.chiTietBaoCao()
      } else {
        this.linhVucCbx = null
        this.chiTietBaoCao()
      }
    },
    chiTietBaoCao() {
      if (!(this.filter.tuNgay || this.filter.denNgay)) {
        const date = this.layThoiGianBaoCao()
        this.filter.tuNgay = date.dateBefore
        this.filter.denNgay = date.dateAfter
      }
      const userInfo = JSON.parse(localStorage.getItem('userInfo'))
      const payload = {
        baoCaoId: this.baoCaoId,
        donViId: userInfo ? userInfo.donViTrucThuoc.id : '',
        tuNgay: formatDateRequest(this.filter.tuNgay),
        denNgay: formatDateRequest(this.filter.denNgay),
        tanSuatBaoCao: 'THANG',
      }
      this.$axios.post(END_POINTS.BAO_CAO_THONG_KE.CHI_TIET_NHAP_LIEU_THONG_KE, payload, false).then(res => {
        const keyTable = Object.keys(this.tableData[0])
        this.tableData = [...TABLE_DEFAULT]
        if (res.data.succeeded && res.data.data?.length > 0 && this.baoCaoId) {
          this.tableData.splice(1, 0, ...res.data.data)
          this.listBackup = [...this.tableData]
          keyTable.forEach(key => {
            this.tableData[this.tableData.length - 1][key] = res.data.data.reduce((result, nextItem) => result + (Number(nextItem[key]) || 0), 0)
          })
          this.linhVucCbx.forEach((linhVuc, index) => {
            if (res.data.data[0].dataFilterLinhVucId.includes(linhVuc.id)) {
              this.linhVucCbx[index].check = true
            } else {
              this.linhVucCbx[index].check = false
            }
          })
        } else {
          // this.tableData = this.tableData.filter(row => !row.linhVucId)
          this.chonTatCaLinhVuc()
          keyTable.forEach(key => {
            this.tableData.forEach((row, index) => {
              if (Number(row[key])) {
                this.tableData[index][key] = 0
              }
            })
          })
        }
      })
    },
    inputLinhVuc(value, id) {
      if (value) {
        this.filter.dataLinhVucId.push(id)
        const linhVuc = this.linhVucCbx.find(lv => lv.id === id)
        const obj = this.listBackup.find(item => item.linhVucId === id)
        const item = {
          STT: 1,
          tenLinhVuc: linhVuc ? linhVuc.name : '',
          linhVucId: id,
          nhanGiaiQuyet_TongSoHoSo: obj?.nhanGiaiQuyet_TongSoHoSo || 0,
          nhanGiaiQuyet_TonTruoc: obj?.nhanGiaiQuyet_TonTruoc || 0,
          nhanGiaiQuyet_TrongKy_Tong: obj?.nhanGiaiQuyet_TrongKy_Tong || 0,
          nhanGiaiQuyet_TrongKy_MotCua: obj?.nhanGiaiQuyet_TrongKy_MotCua || 0,
          nhanGiaiQuyet_TrongKy_TrucTuyen: obj?.nhanGiaiQuyet_TrongKy_TrucTuyen || 0,
          kqgQ_TongSo: obj?.kqgQ_TongSo || 0,
          kqgQ_TinhHinhThucHien_TruocHan: obj?.kqgQ_TinhHinhThucHien_TruocHan || 0,
          kqgQ_TinhHinhThucHien_DungHan: obj?.kqgQ_TinhHinhThucHien_DungHan || 0,
          kqgQ_TinhHinhThucHien_QuaHan: obj?.kqgQ_TinhHinhThucHien_QuaHan || 0,
          kqgQ_TuChoiGiaiQuyet: obj?.kqgQ_TuChoiGiaiQuyet || 0,
          kqgQ_TraKetQua_DaTra: obj?.kqgQ_TraKetQua_DaTra || 0,
          kqgQ_TraKetQua_ChuaTra: obj?.kqgQ_TraKetQua_ChuaTra || 0,
          dangGiaiQuyet_TongSo: obj?.dangGiaiQuyet_TongSo || 0,
          dangGiaiQuyet_TrongHan: obj?.dangGiaiQuyet_TrongHan || 0,
          dangGiaiQuyet_QuaHan: obj?.dangGiaiQuyet_QuaHan || 0,
          tamDungBoSungDieuKien: obj?.tamDungBoSungDieuKien || 0,
          rutKhongGiaiQuyet: obj?.rutKhongGiaiQuyet || 0,
          tyLeSomVaDungHan: obj?.tyLeSomVaDungHan || 0,
        }
        this.tableData.splice(1, 0, item)
      } else {
        this.filter.dataLinhVucId = this.filter.dataLinhVucId.filter(item => item !== id)
        this.tableData = this.tableData.filter(row => row.linhVucId !== id)
      }
      if (!this.tableData) {
        const keyTable = Object.keys(this.tableData[0])
        keyTable.forEach(key => {
          this.tableData.forEach((row, index) => {
            if (Number(row[key])) {
              this.tableData[index][key] = 0
            }
          })
        })
      } else {
        const keyTable = Object.keys(this.tableData[0])
        keyTable.forEach(field => {
          this.tableData[this.tableData.length - 1][field] = this.tableData.filter((item, index) => ![this.tableData.length - 1, 0].includes(index)).reduce((result, nextItem) => result + Number(nextItem[field]), 0)
        })
      }
    },
    inputDonVi(value, id) {
      if (value) {
        this.filter.dataDonViId.push(id)
      } else {
        this.filter.dataDonViId = this.filter.dataDonViId.filter(item => item !== id)
      }
    },
    listToTree(array, parent, tree) {
      // eslint-disable-next-line no-param-reassign
      tree = typeof tree !== 'undefined' ? tree : []
      // eslint-disable-next-line no-param-reassign
      parent = typeof parent !== 'undefined' ? parent : { donViId: '00000000-0000-0000-0000-000000000000' }
      const children = _filter(array, child => child.iD_DonVi_Cha === parent.donViId)
      if (!_isEmpty(children)) {
        if (parent.donViId === '00000000-0000-0000-0000-000000000000') {
          // eslint-disable-next-line no-param-reassign
          tree = children
        } else {
          // eslint-disable-next-line no-param-reassign
          parent.children = children
        }
        children.forEach(child => {
          this.listToTree(array, child)
        })
      }
      return tree
    },
    normalizer(node) {
      return {
        id: node.id,
        label: node.name,
        label2: removeDiacritical(node.name),
      }
    },
    normalizer_DonVi(node) {
      if (node.children) {
        return {
          id: node.donViId,
          label: node.tenDonVi,
          children: node.children,
        }
      }
      return {
        id: node.donViId,
        label: node.tenDonVi,
      }
    },
    disabledYear(date) {
      const dateBefore = new Date()
      const dateAfter = new Date()
      if (this.time.year) {
        dateBefore.setFullYear(this.time.year - 1, 11, 14)
        dateAfter.setFullYear(this.time.year, 11, 14)
      }
      return date < dateBefore || date > dateAfter
    },
    activeTime(id) {
      this.activeTimeSelect = id
      this.timeOption.forEach((time, index) => {
        this.timeOption[index].active = false
        if (time.id === id) {
          this.timeOption[index].active = true
        }
      })
    },
    chonTatCaLinhVuc() {
      this.linhVucCbx.forEach((item, index) => {
        this.linhVucCbx[index].check = true
      })
      const item = []
      this.linhVucCbx.forEach(linhVuc => {
        const includeLinhVuc = this.tableData.some(row => row.linhVucId === linhVuc.id)
        const obj = this.listBackup.find(list => list.linhVucId === linhVuc.id)
        if (!includeLinhVuc) {
          item.push({
            STT: 1,
            tenLinhVuc: linhVuc.name,
            linhVucId: linhVuc.id,
            nhanGiaiQuyet_TongSoHoSo: obj?.nhanGiaiQuyet_TongSoHoSo || 0,
            nhanGiaiQuyet_TonTruoc: obj?.nhanGiaiQuyet_TonTruoc || 0,
            nhanGiaiQuyet_TrongKy_Tong: obj?.nhanGiaiQuyet_TrongKy_Tong || 0,
            nhanGiaiQuyet_TrongKy_MotCua: obj?.nhanGiaiQuyet_TrongKy_MotCua || 0,
            nhanGiaiQuyet_TrongKy_TrucTuyen: obj?.nhanGiaiQuyet_TrongKy_TrucTuyen || 0,
            kqgQ_TongSo: obj?.kqgQ_TongSo || 0,
            kqgQ_TinhHinhThucHien_TruocHan: obj?.kqgQ_TinhHinhThucHien_TruocHan || 0,
            kqgQ_TinhHinhThucHien_DungHan: obj?.kqgQ_TinhHinhThucHien_DungHan || 0,
            kqgQ_TinhHinhThucHien_QuaHan: obj?.kqgQ_TinhHinhThucHien_QuaHan || 0,
            kqgQ_TuChoiGiaiQuyet: obj?.kqgQ_TuChoiGiaiQuyet || 0,
            kqgQ_TraKetQua_DaTra: obj?.kqgQ_TraKetQua_DaTra || 0,
            kqgQ_TraKetQua_ChuaTra: obj?.kqgQ_TraKetQua_ChuaTra || 0,
            dangGiaiQuyet_TongSo: obj?.dangGiaiQuyet_TongSo || 0,
            dangGiaiQuyet_TrongHan: obj?.dangGiaiQuyet_TrongHan || 0,
            dangGiaiQuyet_QuaHan: obj?.dangGiaiQuyet_QuaHan || 0,
            tamDungBoSungDieuKien: obj?.tamDungBoSungDieuKien || 0,
            rutKhongGiaiQuyet: obj?.rutKhongGiaiQuyet || 0,
            tyLeSomVaDungHan: obj?.tyLeSomVaDungHan || 0,
          })
        }
      })
      this.tableData.splice(1, 0, ...item)
      if (!this.tableData) {
        const keyTable = Object.keys(this.tableData[0])
        keyTable.forEach(key => {
          this.tableData.forEach((row, index) => {
            if (Number(row[key])) {
              this.tableData[index][key] = 0
            }
          })
        })
      } else {
        const keyTable = Object.keys(this.tableData[0])
        keyTable.forEach(field => {
          this.tableData[this.tableData.length - 1][field] = this.tableData.filter((checkItem, index) => ![this.tableData.length - 1, 0].includes(index)).reduce((result, nextItem) => result + Number(nextItem[field]), 0)
        })
      }
    },
    boChonTatCaLinhVuc() {
      this.filter.dataLinhVucId = []
      this.linhVucCbx.forEach((item, index) => {
        this.linhVucCbx[index].check = false
        return item.id
      })
      this.tableData = this.tableData.filter(row => !row.linhVucId)
      const keyTable = Object.keys(this.tableData[0])
      keyTable.forEach(key => {
        this.tableData.forEach((row, index) => {
          if (Number(row[key])) {
            this.tableData[index][key] = 0
          }
        })
      })
    },
    onPrint() {
      window.frames.print_frame.document.title = document.title
      window.frames.print_frame.document.body.innerHTML = `<!DOCTYPE html>
      <html>
        <title> In báo cáo thống kê </title>
        <head>
        <style>
            th.ve-table-header-th {
  background-color: #fafafa;
  padding: 10px;
  font-weight: 500;
  color: #000000d9;
  font-size: 14px;
  background-color: #f3f4f7 !important;
    border-bottom: 1px solid #dcdfe6 !important;
    border-right: 1px solid #dcdfe6 !important;
}
.empty-data {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 100%;
  color: #666;
  font-size: 16px;
  border: 1px solid #eee;
  border-top: 0;
}
tbody td {
text-align: center;
background-color: #fff;
color: #000000d9;
height: inherit;
font-size: 14px;
border-right: 1px solid #eee;
border-bottom: 1px solid #eee;
border-collapse: separate;
border-spacing: 0;
padding: 5px;
}
table {
border: 1px solid #dcdfe6;
border-right: none !important;
border-bottom: none !important;
}
        </style>
        </head>
        <body>
          <div>
            <div class="text">
              <div>
                  <div style="text-align: center">
                     <h3>BÁO CÁO TỔNG HỢP TÌNH HÌNH GIẢI QUYẾT THỦ TỤC HÀNH CHÍNH CỦA ĐƠN VỊ</h3>
                  </div>
                </div>
              <div>
                <h4 style="text-align: center">
                      Kỳ báo cáo : ${this.getTimeSelected()}
                </h4>
                <h4 style="text-align: center">
                      (Từ ngày ${formatDate(this.filter.tuNgay)} đến ngày ${formatDate(this.filter.denNgay)})
                </h4>
                <h4 style="text-align: right">
                      Đơn vị tính : Số hồ sơ
                </h4>
              </div>
            </div>
            ${this.$refs.docBody.innerHTML}
          </div>
        </body>
      </html>`
      window.frames.print_frame.window.focus()
      window.frames.print_frame.window.print()
    },
    getTimeSelected() {
      switch (this.activeTimeSelect) {
        case 1: return 'Tháng'
        case 2: return 'Quý'
        case 3: return 'Năm'
        case 4: return 'Tuỳ chọn'
        default: return '(Theo tiêu chí thời gian được chọn)'
      }
    },
    layDanhSachHoSo(hoSoId) {
      if (hoSoId) {
        this.dataHoSoId = hoSoId
      }
      let payload = {
        dataHoSoId: this.dataHoSoId,
        dataTTHCId: [],
        dataLinhVucId: [],
        fulltextsearch: '',
        chuHoSo: '',
        maHoSo: '',
        diaChiChuHoSo: '',
        thoiGianTiepNhanTu: '',
        thoiGianTiepNhanDen: '',
        thoiGianHenTraTu: '',
        thoiGianHenTraDen: '',
        trangThaiHoSo: [],
        loaiTrangThaiHoSo: [],
        loaiTrangThaiConHanQuaHan: 0,
        trangThaiConHanQuaHan: 0,
        navCode: '',
        pageNumber: 1,
        pageSize: 10,
      }
      if (this.$refs.hoSo) {
        payload = {
          ...payload,
          ...this.$refs.hoSo.getCurrentPage(),
        }
      }
      this.$nextTick(() => {
        this.$axios.post(END_POINTS.BAO_CAO_THONG_KE.DANH_SACH_HO_SO, payload, false).then(res => {
          if (res.data && res.data.succeeded) {
            this.rows = res.data.data
            this.tongSoHoSo = res.data.totalCount
            this.$refs.commonModal.showModal()
          }
        })
      })
    },
    setTotal(field, rowIndex, event) {
      this.tableData[rowIndex][field] = event.target.value
      this.tableData[this.tableData.length - 1][field] = this.tableData
        .filter((item, index) => ![this.tableData.length - 1, 0].includes(index))
        .reduce((result, nextItem) => result + Number(nextItem[field]), 0)
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.card .card-header {
  border-bottom: 1.5px solid #EEEEEE !important;
  display: inline-block;
  padding: 0.5rem 1.5rem !important;
  margin: 0;
}
.text-form{
  font-size:12px;
  font-weight:bold;
}
.text{
  padding-top:5rem;
  word-break: break-word;
}
.selectYear{
  width:10%;
  height:37px;
  border-radius:4px;
  border: 1px solid #d8d6de;
  padding-left:5px;
  color:gray;
  outline:none;
}
.border-card{
  margin-bottom:-0.8rem !important;
}
.outline-white{
  background: #FFFFFF !important;
}
.card-body{
  padding-top:10px;
}
.btn{
  border: 1px solid #ddd;
}
.body-card{
  max-height:140px;
  overflow-x : hidden;
}
.body-card::-webkit-scrollbar {
  width: 3px;
  background-color:#E5E5E5;
}
.body-card::-webkit-scrollbar-thumb {
  background-color: gray;
}
.body-card::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  background-color:#ffff;
}
.text-link{
  color:#1270BE;
  text-decoration: underline;
  padding-bottom: 5px;
  cursor:pointer;
}
.custom-control-inline{
  margin-bottom:15px;
}
.custom-cell {
  color:#1270BE;
  cursor:pointer;
}

.custom-control {
  height: 2rem !important;
  padding: 0 4px;
}
.text-check {
  text-align: left;
}
.text-weight {
   font-weight: 900;
}
.ve-table .ve-table-container table.ve-table-content tbody.ve-table-body tr.ve-table-body-tr td.ve-table-body-td,.ve-table .ve-table-container table.ve-table-content tbody.ve-table-body tr.ve-table-expand-tr td.ve-table-body-td{
  text-align: right;
}
.ve-table .ve-table-container table.ve-table-content tbody.ve-table-body tr.ve-table-body-tr,.ve-table .ve-table-container table.ve-table-content tbody.ve-table-body tr.ve-table-expand-tr {
  text-align: center;
}
.text-right-check {
  justify-content: end;
  font-weight: 900;
  display: flex;
  align-items: center;
}
</style>
